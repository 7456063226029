/* .ant-table-tbody .chart-config-item-layout {
  width: 200px;
  display: inline-block;
  padding-left: 10px;
} */
/* 表头分组单元格样式设置 */
.ant-table-tbody .chart-config-item-layout:first-child {
  width: 20% !important;
  display: inline-block;
}
.ant-table-tbody .chart-config-item-layout:nth-child(2) {
  width: 55% !important;
  display: inline-block;
}
.ant-table-tbody .chart-config-item-layout:nth-child(3) {
  width: 20% !important;
  display: inline-block;
}
/* 设置对齐方式下拉的样式长度 */
.ant-table-tbody .chart-config-item-layout:nth-child(3)>div {
  width: 120px !important;
}
.ant-table-tbody .chart-config-item-layout:nth-child(4) {
  width: 30% !important;
  display: inline-block;
}
.ant-table-tbody .chart-config-item-layout:nth-child(5) {
  width: 30% !important;
  display: inline-block;
}
.ant-table-tbody .chart-config-item-layout:nth-child(6) {
  width: 30% !important;
  display: inline-block;
}
.ant-table-tbody .ant-form-item-label {
  max-width: 80px;
}
.ant-table-tbody .ant-form-item {
  display: inline-block;
}

.ant-table-tbody .ant-form-item-control {
  display: inline-block;
}

.ant-table-tbody .ant-form-item-control-input-content {
  width: 220px;
}
.ant-select .datart-ant-select .ant-select-single .ant-select-show-arrow {
  max-width: 120px !important;
}
.ant-table-tbody .chart-config-item-layout .chart-config-item-layout .ant-form-item-control {
  max-width: 120px !important;
}
div.ant-form-item-control-input-content>div.ant-table-tbody .chart-config-item-layout .chart-config-item-layout .ant-form-item-control{
  max-width: 120px !important;
}
/* .ant-table-tbody .ant-form-item-control-input {
  width: 80px;
} */