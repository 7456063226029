/* 设置一般的上方tab样式 */
.board-provider>div:first-child {
  padding: 0px !important;
}

.board-provider>div>h1 {
  font-size: 10px;
}

/* .ant-tabs-tab-with-remove {
  padding: 2px 4px !important;
  margin: 2px !important;
} */
/* 设置图表类的的上方tab样式 */
.datart-viz>div:nth-child(n+2)>div>div>div:first-child {
  padding: 0px !important;
}
.datart-viz>div:nth-child(n+2)>div>div>div:first-child>div>h1 {
  font-size: 10px !important;
}

th {
  padding: 0px !important;
}
/* 输入框 清空控件 */
.control-input>span>span.ant-input-suffix {
  bottom: 50%;
  top: 50%;
  right: 30px !important;
  position: absolute;
}
/* 日期范围选择 清空控件 */
.ant-picker-clear>span {
  bottom: -7px !important;
  right: 20px !important;
  position: absolute;
}
/* 下拉选择 清空控件 */
.ant-select-clear>span {
  bottom: -1px !important;
  right: 20px !important;
  position: absolute;
}

.modal-simple>div {
  height: 85vh;
}

.ant-form-horizontal .ant-form-item-control {
  background-color: #fff;
}

/*table tr td {*/
/*  border-left: 1px solid #000000 !important;*/
/*}*/
/*table tr th {*/
/*  border-top: 1px solid #000000 !important;*/
/*  border-left: 1px solid #000000 !important;*/
/*}*/

/*table tr th:last-child {*/
/*  border-right: 1px solid #000000 !important;*/
/*}*/
/*table tr td:last-child {*/
/*  border-right: 1px solid #000000 !important;*/
/*}*/

/** {*/
/*  filter:blur(0) !important;*/
/*  -webkit-filter:blur(0) !important;*/
/*  -moz-filter:blur(0) !important;*/
/*  -ms-filter:blur(0) !important;*/
/*  transform:translateZ(0) !important;*/
/*  -webkit-transform:translateZ(0) !important;*/
/*  -moz-transform:translateZ(0) !important;*/
/*  -ms-transform:translateZ(0) !important;*/
/*}*/

html,body { -webkit-text-size-adjust:none; }

/*.ant-table-header table {*/
/*  !*border-top: 1px solid #000000 !important;*!*/
/*  !*border-left: 1px solid #000000 !important;*!*/
/*  border-right: 0 solid #000000 !important;*/
/*  border-bottom: 0 solid #000000 !important;*/
/*  border-collapse: collapse;*/
/*}*/

/*.ant-table-body table {*/
/*  border-radius: 0 !important;*/
/*  border-top: 0 solid #000000 !important;*/
/*  !*border-left: 1px solid #000000 !important;*!*/
/*  border-right: 0 solid #000000 !important;*/
/*  border-bottom: 0 solid #000000 !important;*/
/*  border-collapse: collapse;*/
/*}*/

/*.ant-table-header tr th {*/
/*  border-top: 0 solid #000000 !important;*/
/*  border-left: 0 solid #000000 !important;*/
/*  !*border-right: 1px solid #000000 !important;*!*/
/*  !*border-bottom: 1px solid #000000 !important;*!*/
/*}*/

/*.ant-table-body tr:not(:first-child) td {*/
/*  border-top: 0 solid #000000 !important;*/
/*  border-left: 0 solid #000000 !important;*/
/*  !*border-right: 1px solid #000000 !important;*!*/
/*  !*border-bottom: 1px solid #000000 !important;*!*/
/*}*/

/* .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td{
  border-right: 1px solid black !important;
  border-left: 1px solid black !important;
  border-bottom: 1px solid black !important;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td{
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
  border-top: 1px solid black !important;
}

.ant-table.ant-table-bordered table {
  margin-bottom: -1px;
  border-right: 1px solid black;
} */

.ant-table.ant-table-bordered div.ant-table-summary {
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
}

/*.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {*/
/*  content: unset;*/
/*}*/
/*.ant-table-header table tr:last-child th {*/
/*  border-top: 0 solid #000000 !important;*/
/*}*/

/*.ant-table-thead tr:first-child th:first-child {*/
/*  border-left: 1px solid #000000 !important;*/
/*}*/
/*.ant-table-thead tr:first-child th {*/
/*  border-top: 1px solid #000000 !important;*/
/*}*/
/*.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {*/
/*  border-right: 1px solid #000000 !important;*/
/*}*/
/*.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child) > th {*/
/*  border-bottom: 1px solid #000000 !important;*/
/*}*/
/*.ant-table-thead > tr > th {*/
/*  border-bottom: 1px solid #000000 !important;*/
/*}*/

/*.ant-table-tbody tr:first-child td {*/
/*  border-bottom: 0 solid #000000 !important;*/
/*}*/

/*.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr:not(:first-child)>td {*/
/*  border-bottom: 1px solid #000000 !important;*/
/*}*/
/*.ant-table-tbody tr td:first-child {*/
/*  border-left: 1px solid #000000 !important;*/
/*}*/

#tp-watermark div:nth-child(2n) {
  padding-left: 200px;
}

/* .ant-table.ant-table-bordered > .ant-table-container {
  border-left: 0 solid #000000 !important;
} */

th[colspan] {
  padding-top:1px !important;
  padding-bottom:1px !important;
}
.ant-tree-treenode {
  padding: 0 !important;
  margin: 0 4px !important;
  font-size: 12px !important;
}
.ant-tree.ant-tree-directory .ant-tree-treenode:before{
    bottom: 0 !important;
    border-radius: 4px !important;
}
.ant-table-pagination.ant-pagination{
  margin: 4px 0 !important;
}

.anticon-loading-3-quarters {
  color: #e62412 !important;
  opacity: 0.8;
}

.linkmodal > .ant-modal-content {
  position: relative;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0;
  border-radius: 4px;
  background-clip: padding-box;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
}
.linkmodal > .ant-modal-content > .ant-modal-body {
  padding: 0 !important;
  font-size: 0 !important;
  line-height: 1 !important;
}
.linkmodal > .ant-modal-content > .ant-modal-confirm .ant-modal-body {
  padding: 0px !important;
}

.linkmodal .board-provider>div:first-child {
  display: none;
}

.ant-table-tbody > tr:not(:first-child) > td {
  padding: 3px !important;
}

tfoot.ant-table-summary td.ant-table-cell:last-of-type{
  padding-right: 17px !important;
}

.row-dragging {
  background: #fafafa !important;
  border: 1px solid #ccc;
  z-index: 10000 !important;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}



.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child) > th {
  border-bottom-color: var(--tableBorderColor, black) !important;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
  border-right-color: var(--tableBorderColor, black) !important;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  border-top-color: var(--tableBorderColor, black) !important;
}

.ant-table.ant-table-bordered > .ant-table-container {
  border-left-color:var(--tableBorderColor, black) !important;
}

.ant-table.ant-table-bordered .ant-table-tbody > tr > td {
  border-bottom-color: var(--tableBorderColor, black) !important;
}

.ant-table.ant-table-bordered .ant-table-thead > tr > th {
  border-bottom-color: var(--tableBorderColor, black) !important;
}

.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none !important;
}

.ant-tabs.ant-tabs-top.ant-tabs-large.ant-tabs-centered {
  border-bottom: none;
}
 .navigation-bar-tabs .ant-tabs-tab{
    background: none !important;
    border-radius: 4px !important;
    color: #fff !important;
    opacity: 0.8 !important;
    padding: 6px 4px !important;
    margin: 4px !important;
}
/* .anticon svg{
    color: #fff !important;
} */

.navigation-bar-tabs .ant-tabs-tab:hover{
    color: #fff !important;
    opacity: 1 !important;
}

.navigation-bar-tabs .ant-tabs-nav .ant-tabs-tab-active{
    background:  #1a8cd9 !important;
    opacity: 1 !important;

}
.navigation-bar-tabs.ant-tabs-card.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab:hover, .ant-tabs.ant-tabs-card.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab:hover{
    color: #fff !important;
}
.navigation-bar-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #fff !important;
}
.navigation-bar-tabs .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab-with-remove{
    margin: 4px !important;
    text-align: center !important;
}

.ant-tree-treenode:hover::before{
    background: #E8F4FD !important;
    color: #000000 !important;
}
 /* .ant-tree-treenode-switcher-open{
    color: #fff !important;
    border-radius: 4px !important;
    background-color: #1B9AEE !important;
}
.ant-tree-treenode-switcher-open:hover:before{
    background: #1B9AEE !important;
} */
.ant-tabs-nav-operations svg{
    color: #fff !important;
}

.ant-tree-treenode-selected::before{
    background:  #1B9AEE !important;
    color: #fff !important;
}
.ant-tree-treenode-selected:hover::before{
    background:  #1B9AEE !important;
    color: #fff !important;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected{
    background:  #1B9AEE ;
    color: #fff !important;
}
/* .ant-tree.ant-tree-directory .ant-tree-treenode:before{
    background: #1B9AEE !important;
    opacity: 0.1 !important;
} */
.navigation-bar-tabs .ant-tabs-nav-wrap {
    display: flex !important;
    align-items: center !important;
    flex-direction: row !important;
    margin: 3px !important;
}
.navigation-bar-tabs .ant-tabs-tab-remove{
    color: #fff !important;
    overflow: hidden !important;
    margin-left: 0 !important;
}
.navigation-bar-tabs .ant-tabs-tab-active .ant-tabs-tab-remove{
    color: #fff !important;
}

.ant-page-header.s2-header {
  margin: 0 !important;
}

.ant-modal.ant-modal-confirm.ant-modal-confirm-info.dialog-content-by-url-modal .ant-modal-confirm-content{
  margin: 0;
}

.ant-tabs-dropdown-menu-item {
  position: relative;
}
.navigation-bar-tabs{
    height: 40px !important;
    background-color:  #1B9AEE !important;
}

.JumpVizDialogModal .ant-modal-content .ant-modal-body {
  padding: 10px;
}

.JumpVizDialogModal .ant-modal-close {
  right: -15px;
  top: -15px;
}

.JumpVizDialogModal .ant-modal-confirm-btns {
  display: none;
}

.ant-select-item.ant-select-item-option.select-option .select-option-inner{
  white-space: break-spaces;
  word-break: break-all;
  word-wrap: break-word;
}