@font-face {
  font-family: "iconfont"; /* Project id 2869064 */
  src: url('iconfont.woff2?t=1645528895434') format('woff2'),
       url('iconfont.woff?t=1645528895434') format('woff'),
       url('iconfont.ttf?t=1645528895434') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 20px;
  line-height: 20px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-CombinedShape:before {
  content: "\e741";
}

.icon-kanban:before {
  content: "\e806";
}

.icon-gitee:before {
  content: "\e611";
}

.icon-rich-text:before {
  content: "\e7a4";
}

.icon-graph-circular:before {
  content: "\e7d0";
}

.icon-graph:before {
  content: "\e6a8";
}

.icon-waterfall:before {
  content: "\e617";
}

.icon-treemap:before {
  content: "\e616";
}

.icon-sankey:before {
  content: "\e618";
}

.icon-parallel:before {
  content: "\e613";
}

.icon-graph-force:before {
  content: "\e614";
}

.icon-radar:before {
  content: "\e615";
}

.icon-gauge:before {
  content: "\e607";
}

.icon-chart:before {
  content: "\e635";
}

.icon-area-chart:before {
  content: "\e654";
}

.icon-areachart:before {
  content: "\ebc7";
}

.icon-fsux_tubiao_ditu:before {
  content: "\e610";
}

.icon-ditu:before {
  content: "\e72e";
}

.icon-fsux_tubiao_ciyun:before {
  content: "\e60f";
}

.icon-fsux_tubiao_shuangzhoutu:before {
  content: "\e60d";
}

.icon-fsux_tubiao_loudoutu:before {
  content: "\e60e";
}

.icon-fsux_tubiao_bingtu:before {
  content: "\e60a";
}

.icon-fsux_tubiao_bingtu1:before {
  content: "\e60b";
}

.icon-fsux_tubiao_nandingmeiguitu:before {
  content: "\e60c";
}

.icon-fsux_zhexiantu:before {
  content: "\e608";
}

.icon-sandiantu:before {
  content: "\e7a0";
}

.icon-fsux_tubiao_zhuzhuangtu:before {
  content: "\e601";
}

.icon-fsux_tubiao_duijizhuzhuangtu:before {
  content: "\e602";
}

.icon-fsux_tubiao_duijizhuzhuangtu1:before {
  content: "\e603";
}

.icon-fsux_tubiao_zhuzhuangtu1:before {
  content: "\e604";
}

.icon-fsux_tubiao_baifenbiduijizhuzhuangtu:before {
  content: "\e605";
}

.icon-fsux_tubiao_baifenbiduijitiaoxingtu:before {
  content: "\e606";
}

.icon-fenzubiao:before {
  content: "\e799";
}

.icon-mingxibiao:before {
  content: "\e79a";
}

.icon-fanpaiqi:before {
  content: "\e7a2";
}

.icon-fasongyoujian:before {
  content: "\e600";
}

.icon-shujukupeizhi:before {
  content: "\e65f";
}

.icon-24gf-table:before {
  content: "\eb12";
}

.icon-xietongzhihuidaping:before {
  content: "\e631";
}

.icon-users1:before {
  content: "\e92e";
}

